import { IconName } from '@fortawesome/fontawesome-common-types'
import { Box, Button, Stack } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback, useMemo } from 'react'
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import {
    NudgeType,
    SmartNudgeReset
} from '../../../graphql/generated/autogenerated'
import {
    BrowserNotificationPermission,
    useBrowserNotification
} from '../../../hooks/useBrowserNotification/useBrowserNotification'
import {
    BrowserNotificationPermissionType,
    ChallengeGoalReminder,
    Notification
} from '../../../hooks/useGetRecentNotifications/useGetRecentNotifications'
import { defaultResourceSelectedProps } from '../../../utils/defaultTrackingProps'

// We are using this override to match designs until we get a small version of the buttons.
export const buttonSxOverride = {
    maxWidth: '32px!important',
    minHeight: '32px!important',
    minWidth: '32px!important'
}

interface Messages {
    testButton: MessageDescriptor
    resetNudgeMessage: MessageDescriptor
    watchResetButton: MessageDescriptor
    challengeReminderNudgeMessage: MessageDescriptor
    viewChallengeButton: MessageDescriptor
    browserNotificationPermissionTitle: MessageDescriptor
}

export const nudgeMessages: Messages = {
    testButton: {
        id: 'testButton',
        defaultMessage: 'CTA Click',
        description: 'cta click button'
    },
    resetNudgeMessage: {
        id: 'resetNudgeMessage',
        defaultMessage:
            'Now would be a great time to reduce stress in 60 seconds with a Reset.',
        description: `reset nudge message`
    },
    watchResetButton: {
        id: 'watchResetButton',
        defaultMessage: 'Watch Reset',
        description: 'watch reset button'
    },
    challengeReminderNudgeMessage: {
        id: 'challengeReminderNudgeMessage',
        defaultMessage:
            'You can earn more plants! Complete all your daily Challenge goals.',
        description: 'challenge nudge message'
    },
    viewChallengeButton: {
        id: 'viewChallengeButton',
        defaultMessage: 'Go to Challenges',
        description: 'view challenge button'
    },
    browserNotificationPermissionTitle: {
        id: 'browserNotificationPermissionTitle',
        defaultMessage: 'Stay on top of your goals with browser notifications',
        description: 'browser notification permission title'
    }
}

export interface NotificationItemProps {
    notification: Notification
    handleClose: (notification?: Notification) => void
    setClicked: (notification: Notification) => void
    setOpenPermissionSnackbar?: (allowed: boolean) => void
}

const NotificationItem: React.FC<NotificationItemProps> = ({
    notification,
    handleClose,
    setClicked,
    setOpenPermissionSnackbar
}) => {
    const history = useHistory()
    const { formatMessage } = useIntl()

    const { requestPermission } = useBrowserNotification()
    const {
        notificationIcon,
        notificationMessage,
        notificationButtonTitle,
        notificationCtaRoute,
        notificationCanDismiss
    } = useMemo(() => {
        const metadata = notification.nudgeMetadata as any
        switch (notification.nudgeType) {
            case NudgeType.TestNudge:
                return {
                    notificationIcon: 'bell',
                    notificationMessage: metadata?.testValue,
                    notificationButtonTitle: formatMessage(
                        nudgeMessages.testButton
                    ),
                    notificationCtaRoute: metadata?.testCtaUrl,
                    notificationCanDismiss: true
                }
            case NudgeType.SmartNudgeMsTeamsMvp:
                return {
                    notificationIcon: 'face-relieved',
                    notificationMessage: formatMessage(
                        nudgeMessages.resetNudgeMessage
                    ),
                    notificationButtonTitle: formatMessage(
                        nudgeMessages.watchResetButton
                    ),
                    notificationCtaRoute: `/reset/thrive/${metadata?.resetId}?resetType=nudge_reset`,
                    notificationCanDismiss: true
                }
            case ChallengeGoalReminder:
                return {
                    notificationIcon: 'flag',
                    notificationMessage: formatMessage(
                        nudgeMessages.challengeReminderNudgeMessage
                    ),
                    notificationButtonTitle: formatMessage(
                        nudgeMessages.viewChallengeButton
                    ),
                    notificationCtaRoute: `/challenges`,
                    notificationCanDismiss: false
                }
            case BrowserNotificationPermissionType:
                return {
                    notificationIcon: 'bell',
                    notificationMessage: formatMessage(
                        nudgeMessages.browserNotificationPermissionTitle
                    ),
                    notificationButtonTitle: formatMessage({
                        id: 'enableNotifications',
                        defaultMessage: 'Allow',
                        description: 'enable notifications button'
                    }),
                    notificationCtaRoute: undefined,
                    notificationCanDismiss: true
                }
            default:
                return {
                    notificationMessage: undefined,
                    notificationButtonTitle: undefined,
                    notificationCtaRoute: undefined,
                    notificationCanDismiss: false
                }
        }
    }, [formatMessage, notification])

    const dismissPermissionNotification = useCallback(
        (permission: boolean) => {
            setClicked(notification)
            setOpenPermissionSnackbar?.(permission)
        },
        [notification, setClicked, setOpenPermissionSnackbar]
    )

    const openPermission = useCallback(async () => {
        const permission = await requestPermission()
        switch (permission) {
            case BrowserNotificationPermission.DENIED:
                handleClose()
                dismissPermissionNotification(false)
                break
            case BrowserNotificationPermission.GRANTED:
                handleClose()
                dismissPermissionNotification(true)
                break
            default:
                break
        }
    }, [dismissPermissionNotification, handleClose, requestPermission])

    const onCtaClick = useCallback(() => {
        // This also needs to be swapped out when we have all of the tracking events.
        // It will be 'notificationTrackingActivityType' when we have the tracking event.
        // It cannot be swapped out now because AVO is type checking the activity type.
        // TODO: Per https://thrive-global.atlassian.net/browse/INTY-211 these also should not all be resourceSelected events
        // for "Allow" click in browser notifs for example, it should be a notificationSubscribed event

        // Quick & dirty fix to only launch this tracking event for SmartNudgeMsTeamsMvp per https://thrive-global.atlassian.net/browse/INTY-229
        switch (notification.nudgeType) {
            case NudgeType.SmartNudgeMsTeamsMvp: {
                const metadata = notification.nudgeMetadata as SmartNudgeReset
                Avo.resourceSelected({
                    ...defaultResourceSelectedProps,
                    featureType: 'notifications',
                    activityType: 'reset_notification_selected',
                    contentId:
                        metadata?.resetId ??
                        defaultResourceSelectedProps.contentId
                })
                break
            }
            default:
                break
        }

        if (notificationCtaRoute) {
            history.push(notificationCtaRoute)
            handleClose()
            const timeout = setTimeout(() => {
                setClicked(notification)
            }, 1000)
            return () => clearTimeout(timeout)
        } else {
            openPermission()
        }
    }, [
        history,
        setClicked,
        handleClose,
        notification,
        openPermission,
        notificationCtaRoute
    ])

    const onDismissClickHandler = useCallback(() => {
        // Quick & dirty fix to only launch this tracking event for SmartNudgeMsTeamsMvp per https://thrive-global.atlassian.net/browse/INTY-229
        switch (notification.nudgeType) {
            case NudgeType.SmartNudgeMsTeamsMvp: {
                const metadata = notification.nudgeMetadata as SmartNudgeReset
                Avo.resourceSelected({
                    ...defaultResourceSelectedProps,
                    featureType: 'notifications',
                    activityType: 'reset_notification_deferred',
                    contentId:
                        metadata?.resetId ??
                        defaultResourceSelectedProps.contentId
                })
                break
            }
            default:
                break
        }

        setClicked(notification)
    }, [notification, setClicked])

    return (
        <Stack
            gap={1.25}
            direction="row"
            alignItems="start"
            data-testid="notification-item"
        >
            <IconButton
                variant="contained"
                color="secondary"
                sx={{
                    pointerEvents: 'none',
                    ...buttonSxOverride
                }}
            >
                <LeafIcon
                    icon={notificationIcon as IconName}
                    fontSize="small"
                />
            </IconButton>
            <Stack flex={1} gap={1}>
                <CoreTypography variant="body1">
                    {notificationMessage}
                </CoreTypography>
                <Stack direction="row" alignItems="center" gap={2}>
                    <Box>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            onClick={onCtaClick}
                            data-testid="notification-item-cta-button"
                        >
                            <CoreTypography>
                                {notificationButtonTitle}
                            </CoreTypography>
                        </Button>
                    </Box>
                    {notificationCanDismiss && (
                        <Box>
                            <Button
                                size="small"
                                onClick={onDismissClickHandler}
                                data-testid="notification-item-dismiss-button"
                            >
                                <CoreTypography>
                                    <FormattedMessage
                                        defaultMessage="Not now"
                                        description="not now button"
                                    />
                                </CoreTypography>
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default NotificationItem
