import {
    isMicrosoftTeamsTest,
    isRunningInWebview
} from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { nudgeMessages } from '../../components/features/NotificationItem/NotificationItem'
import {
    NudgeType,
    SmartNudgeReset,
    TestNudge,
    UserNudgesSentResult
} from '../../graphql/generated/autogenerated'
import { ChallengeGoalReminder } from '../useGetRecentNotifications/useGetRecentNotifications'

const messages = defineMessages({
    thriveTestNudge: {
        defaultMessage: 'Thrive Test Nudge',
        description: `thrive test nudge notification title`
    },
    thriveReset: {
        defaultMessage: 'Thrive Reset',
        description: `thrive reset notification title`
    },
    thriveChallenge: {
        defaultMessage: 'Thrive Challenge',
        description: `thrive challenge notification title`
    }
})

type BrowserNotificationState = 'default' | 'denied' | 'granted'

export enum BrowserNotificationPermission {
    DEFAULT = 'default',
    DENIED = 'denied',
    GRANTED = 'granted'
}

export enum BrowserNotificationType {
    RESET,
    CHALLENGE
}

export interface BrowserNotification {
    title: string
    url: string
    tag: string
    options: NotificationOptions
}

const badgeIconUrl =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/447e218e-e5f5-4136-a26e-8918880a4d00/cms'

interface UseBrowserNotification {
    isPermissionGranted: boolean
    canUseBrowserNotifications: boolean
    requestPermission: () => Promise<BrowserNotificationState>
    showNotification: (
        title: string,
        url: string,
        tag: string,
        options: NotificationOptions
    ) => void
    generateBrowserNotification: (notification: any) => BrowserNotification
}

export const buildUrlForReset = (resetId: string) => {
    return `${window.location.origin}/reset/thrive/${resetId}?resetType=nudge_reset`
}

export const buildUrlForChallenge = () => {
    return `${window.location.origin}/challenges`
}

export const checkBrowserNotificationPermission = () => {
    if (typeof Notification === 'undefined') {
        console.warn('This browser does not support desktop notification')
        return false
    }
    return true
}

export const useBrowserNotification = (): UseBrowserNotification => {
    const { formatMessage } = useIntl()
    const [permission, setPermission] = useState<BrowserNotificationState>(
        typeof Notification !== 'undefined'
            ? Notification.permission
            : BrowserNotificationPermission.DEFAULT
    )
    const isPermissionGranted = useMemo(() => {
        return permission === BrowserNotificationPermission.GRANTED
    }, [permission])

    const isMobileBrowser = () => {
        if (typeof navigator === 'undefined') return false
        return /Mobi|Android/i.test(navigator.userAgent)
    }

    const canUseBrowserNotifications = useMemo(() => {
        return (
            typeof window !== 'undefined' &&
            typeof window.Notification !== 'undefined' &&
            !isRunningInWebview() &&
            !isMicrosoftTeamsTest() &&
            !isMobileBrowser()
        )
    }, [])

    const requestPermission =
        useCallback(async (): Promise<BrowserNotificationState> => {
            if (!checkBrowserNotificationPermission()) {
                return BrowserNotificationPermission.DENIED
            }
            try {
                const newPermission = await Notification.requestPermission()
                const browserNotificationState =
                    newPermission as BrowserNotificationState
                setPermission(browserNotificationState)
                return browserNotificationState
            } catch (error) {
                return BrowserNotificationPermission.DENIED
            }
        }, [])

    const generateBrowserNotification = useCallback(
        (nudge: UserNudgesSentResult): BrowserNotification => {
            switch (nudge?.nudgeType) {
                case NudgeType.TestNudge:
                    return {
                        title: formatMessage(messages.thriveTestNudge),
                        url:
                            (nudge.nudgeMetadata as TestNudge)?.testCtaUrl ??
                            '',
                        tag: `${nudge.nudgeType}.${nudge.sendDate}.${nudge.sendTime}`,
                        options: {
                            body: (nudge.nudgeMetadata as TestNudge)?.testValue
                        } as Notification
                    }
                case NudgeType.SmartNudgeMsTeamsMvp:
                    return {
                        title: formatMessage(messages.thriveReset),
                        url: buildUrlForReset(
                            (nudge.nudgeMetadata as SmartNudgeReset)?.resetId
                        ),
                        tag: `${nudge.nudgeType}.${nudge.sendDate}.${nudge.sendTime}`,
                        options: {
                            body: formatMessage(nudgeMessages.resetNudgeMessage)
                        } as Notification
                    }
                case ChallengeGoalReminder:
                    return {
                        title: formatMessage(messages.thriveChallenge),
                        url: buildUrlForChallenge(),
                        tag: `${nudge.nudgeType}.${nudge.sendDate}.${nudge.sendTime}`,
                        options: {
                            body: formatMessage(
                                nudgeMessages.challengeReminderNudgeMessage
                            )
                        } as Notification
                    }
                default:
                    return {
                        title: '',
                        url: '',
                        tag: '',
                        options: {
                            body: ''
                        } as Notification
                    }
            }
        },
        [formatMessage]
    )

    const showNotification = useCallback(
        (
            title: string,
            url: string,
            tag: string,
            options: NotificationOptions
        ): void => {
            if (!checkBrowserNotificationPermission() || !isPermissionGranted) {
                return
            }

            options.icon = badgeIconUrl
            options.tag = tag
            const notification = new Notification(title, options)

            notification.onclick = (event) => {
                event.preventDefault()
                window.location.href = url
            }
        },
        [isPermissionGranted]
    )

    useEffect(() => {
        if (checkBrowserNotificationPermission()) {
            setPermission(Notification.permission)
        }
    }, [])

    return {
        isPermissionGranted,
        canUseBrowserNotifications,
        requestPermission,
        showNotification,
        generateBrowserNotification
    }
}
