import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { ReactElement, useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    NudgeType,
    SmartNudgeReset,
    UserNudgesSentResult
} from '../../graphql/generated/autogenerated'
import {
    defaultResourceSelectedProps,
    defaultResourceServedProps
} from '../../utils/defaultTrackingProps'
import { ReactNullValue } from '../../utils/nulls'
import { browserNotificationMessages } from '../useBrowserNotification/useBrowserNotification'
import { nudgeMessages } from './i18n/messages'
import {
    BrowserNotificationPermissionType,
    ExtendedNudgeType,
    NewAchievementCountType
} from './pseudo/psuedoNotifications'

export interface WebNotification {
    notificationIcon: string
    notificationMessage: ReactElement
    notificationButtonTitle: string
    notificationCtaRoute: string | undefined
    notificationCanDismiss: boolean
}

export interface BrowserNotification {
    title: string
    url: string
    tag: string
    options: {
        body: string
    }
}

export interface GeneratedNotification {
    webNotification?: WebNotification
    browserNotification?: BrowserNotification
}

export interface Notification extends UserNudgesSentResult {
    read?: boolean
    expired?: boolean
    clicked?: boolean
}

// Helper functions to build URLs for different types of notifications
const buildUrlForReset = (resetId: string) =>
    `${window.location.origin}/reset/thrive/${resetId}?resetType=nudge_reset`

const buildUrlForChallenge = () => `${window.location.origin}/challenges`

const buildUrlForAchievements = () => `${window.location.origin}/achievements`

// Template for creating a web notification
const createWebNotification = (
    icon: string,
    message: ReactElement,
    buttonTitle: string,
    ctaRoute: string | undefined,
    canDismiss?: boolean
): WebNotification => ({
    notificationIcon: icon,
    notificationMessage: message,
    notificationButtonTitle: buttonTitle,
    notificationCtaRoute: ctaRoute,
    notificationCanDismiss: canDismiss ?? false
})

// Template for creating a browser notification
const createBrowserNotification = (
    title: string,
    url: string,
    nudge: UserNudgesSentResult,
    body: string
): BrowserNotification | undefined => {
    return nudge.whereToShow?.some((where) => where === 'WEB_NOTIFY')
        ? {
              title,
              url,
              tag: `${nudge.nudgeType}.${nudge.sendDate}.${nudge.sendTime}`,
              options: { body }
          }
        : undefined
}

const createGenericMessage = (formattedMessage: string) => {
    return <CoreTypography variant="body1">{formattedMessage}</CoreTypography>
}

const createAnnouncementMessage = () => {
    const boldMessage = {
        b: (chunks: React.ReactNode[]) => <strong>{chunks}</strong>
    }
    return (
        <Stack gap={2}>
            <CoreTypography variant="body1">
                <FormattedMessage
                    defaultMessage="Introducing <b>Web Nudges</b>"
                    description="web nudge announcement title"
                    values={boldMessage as any}
                />
            </CoreTypography>
            <CoreTypography variant="body1">
                <FormattedMessage
                    defaultMessage="We'll send occasional reminders to support your well-being goals, starting with Challenge Goal Reminders."
                    description="web nudge announcement message"
                />
            </CoreTypography>
        </Stack>
    )
}

export const useGenerateNotification = (): {
    generateNotification: (nudge: UserNudgesSentResult) => GeneratedNotification
    logResourceServed: (notification: Notification) => void
    logResourceSelected: (notification: Notification) => void
    logResourceDeferred: (notification: Notification) => void
} => {
    const { formatMessage } = useIntl()

    const notificationMapping = useMemo(
        () => ({
            [BrowserNotificationPermissionType]: {
                getNotification: (
                    nudge: UserNudgesSentResult
                ): GeneratedNotification => {
                    return {
                        webNotification: createWebNotification(
                            'bell',
                            createGenericMessage(
                                formatMessage(
                                    nudgeMessages.browserNotificationPermissionTitle
                                )
                            ),
                            formatMessage(nudgeMessages.allow),
                            undefined,
                            true
                        ),
                        browserNotification: undefined
                    }
                }
            },
            [NudgeType.TestNudge]: {
                getNotification: (
                    nudge: UserNudgesSentResult
                ): GeneratedNotification => {
                    const metadata = nudge.nudgeMetadata as any
                    return {
                        webNotification: createWebNotification(
                            'bell',
                            createGenericMessage(
                                metadata?.testValue ?? 'Default test message'
                            ),
                            formatMessage(nudgeMessages.testButton),
                            metadata?.testCtaUrl ?? '/test-url',
                            true
                        ),
                        browserNotification: createBrowserNotification(
                            formatMessage(
                                browserNotificationMessages.thriveTestNudge
                            ),
                            metadata?.testCtaUrl ?? undefined,
                            nudge,
                            metadata?.testValue ??
                                'Default test notification body'
                        )
                    }
                }
            },
            [NudgeType.SmartNudgeMsTeamsMvp]: {
                getNotification: (
                    nudge: UserNudgesSentResult
                ): GeneratedNotification => {
                    const metadata = nudge.nudgeMetadata as any
                    return {
                        webNotification: createWebNotification(
                            'face-relieved',
                            createGenericMessage(
                                formatMessage(nudgeMessages.resetNudgeMessage)
                            ),
                            formatMessage(nudgeMessages.watchResetButton),
                            buildUrlForReset(metadata?.resetId),
                            true
                        ),
                        browserNotification: createBrowserNotification(
                            formatMessage(
                                browserNotificationMessages.thriveReset
                            ),
                            buildUrlForReset(metadata?.resetId),
                            nudge,
                            formatMessage(nudgeMessages.resetNudgeMessage)
                        )
                    }
                }
            },
            [NudgeType.InChallengeGoalReminder]: {
                getNotification: (
                    nudge: UserNudgesSentResult
                ): GeneratedNotification => ({
                    webNotification: createWebNotification(
                        'flag',
                        createGenericMessage(
                            formatMessage(
                                nudgeMessages.challengeReminderNudgeMessage
                            )
                        ),
                        formatMessage(nudgeMessages.viewChallengeButton),
                        buildUrlForChallenge()
                    ),
                    browserNotification: createBrowserNotification(
                        formatMessage(
                            browserNotificationMessages.thriveChallenge
                        ),
                        buildUrlForChallenge(),
                        nudge,
                        formatMessage(
                            nudgeMessages.challengeReminderNudgeMessage
                        )
                    )
                })
            },
            [NewAchievementCountType]: {
                getNotification: (
                    nudge: UserNudgesSentResult
                ): GeneratedNotification => {
                    const metadata = nudge.nudgeMetadata as any
                    return {
                        webNotification: createWebNotification(
                            'award',
                            createGenericMessage(
                                formatMessage(
                                    nudgeMessages.newAchievementTitle,
                                    {
                                        count:
                                            metadata?.newAchievementsCount ?? 1
                                    }
                                )
                            ),
                            formatMessage(nudgeMessages.seeMyAchievements),
                            buildUrlForAchievements()
                        ),
                        browserNotification: createBrowserNotification(
                            formatMessage(
                                browserNotificationMessages.thriveAchievement
                            ),
                            buildUrlForAchievements(),
                            nudge,
                            formatMessage(nudgeMessages.newAchievementTitle, {
                                count: metadata?.newAchievementsCount ?? 1
                            })
                        )
                    }
                }
            },
            [NudgeType.WebNudgesLaunchAnnouncement]: {
                getNotification: (
                    nudge: UserNudgesSentResult
                ): GeneratedNotification => {
                    return {
                        webNotification: createWebNotification(
                            'flag',
                            createAnnouncementMessage(),
                            formatMessage(nudgeMessages.joinAChallenge),
                            buildUrlForChallenge()
                        ),
                        browserNotification: createBrowserNotification(
                            formatMessage(nudgeMessages.webNudgeAnnouncement),
                            buildUrlForChallenge(),
                            nudge,
                            formatMessage(nudgeMessages.webNudgeAnnouncement)
                        )
                    }
                }
            }
        }),
        [formatMessage]
    )

    const generateNotification = useCallback(
        (nudge: UserNudgesSentResult): GeneratedNotification => {
            const notificationConfig = notificationMapping[nudge.nudgeType]
            const notification = notificationConfig?.getNotification(nudge)
            return (
                notification ?? {
                    webNotification: undefined,
                    browserNotification: undefined
                }
            )
        },
        [notificationMapping]
    )

    const logResourceServed = useCallback(
        (notification: Notification): void => {
            switch (notification.nudgeType as NudgeType | ExtendedNudgeType) {
                case ExtendedNudgeType.NewAchievementCountType: {
                    Avo.resourceServed({
                        ...defaultResourceServedProps,
                        featureType: 'notifications',
                        activityType: 'achievement_notification_served',
                        nudgeType: notification.nudgeType
                    })
                    break
                }
                case NudgeType.SmartNudgeMsTeamsMvp: {
                    const metadata =
                        notification.nudgeMetadata as SmartNudgeReset
                    Avo.resourceServed({
                        ...defaultResourceServedProps,
                        featureType: 'notifications',
                        activityType: 'reset_notification_served',
                        nudgeType: notification.nudgeType,
                        contentId:
                            metadata?.resetId ??
                            defaultResourceServedProps.contentId,
                        contentTitle: ''
                    })
                    break
                }
                case NudgeType.InChallengeGoalReminder: {
                    Avo.resourceServed({
                        ...defaultResourceServedProps,
                        featureType: 'notifications',
                        activityType: 'challenge_goal_notification_served',
                        challengeId: ReactNullValue,
                        dayNumber: ReactNullValue
                    })
                    break
                }
                case NudgeType.WebNudgesLaunchAnnouncement: {
                    Avo.resourceServed({
                        ...defaultResourceServedProps,
                        featureType: 'notifications',
                        activityType: 'web_announcement_notification_served',
                        contentId: ''
                    })
                    break
                }
                default:
                    break
            }
        },
        []
    )

    const logResourceSelected = useCallback(
        (notification: Notification): void => {
            switch (notification.nudgeType as NudgeType | ExtendedNudgeType) {
                case ExtendedNudgeType.NewAchievementCountType: {
                    Avo.resourceServed({
                        ...defaultResourceSelectedProps,
                        featureType: 'notifications',
                        activityType: 'achievement_notification_selected',
                        nudgeType: notification.nudgeType,
                        contentId: '',
                        contentTitle: ''
                    })
                    break
                }
                case NudgeType.SmartNudgeMsTeamsMvp: {
                    const metadata =
                        notification.nudgeMetadata as SmartNudgeReset
                    Avo.resourceSelected({
                        ...defaultResourceSelectedProps,
                        featureType: 'notifications',
                        activityType: 'reset_notification_selected',
                        nudgeType: notification.nudgeType,
                        contentId:
                            metadata?.resetId ??
                            defaultResourceSelectedProps.contentId
                    })
                    break
                }
                case NudgeType.InChallengeGoalReminder: {
                    Avo.resourceSelected({
                        ...defaultResourceSelectedProps,
                        featureType: 'notifications',
                        activityType: 'challenge_goal_notification_selected',
                        challengeId: ReactNullValue,
                        dayNumber: ReactNullValue,
                        contentId: ReactNullValue
                    })
                    break
                }
                case NudgeType.WebNudgesLaunchAnnouncement: {
                    Avo.resourceSelected({
                        ...defaultResourceSelectedProps,
                        featureType: 'notifications',
                        activityType: 'web_announcement_notification_selected',
                        contentId: ReactNullValue
                    })
                    break
                }
                default:
                    break
            }
        },
        []
    )

    const logResourceDeferred = useCallback(
        (notification: Notification): void => {
            switch (notification.nudgeType) {
                case NudgeType.SmartNudgeMsTeamsMvp: {
                    const metadata =
                        notification.nudgeMetadata as SmartNudgeReset
                    Avo.resourceSelected({
                        ...defaultResourceSelectedProps,
                        featureType: 'notifications',
                        activityType: 'reset_notification_deferred',
                        nudgeType: notification.nudgeType,
                        contentId:
                            metadata?.resetId ??
                            defaultResourceSelectedProps.contentId
                    })
                    break
                }
                default:
                    break
            }
        },
        []
    )

    return {
        generateNotification,
        logResourceServed,
        logResourceSelected,
        logResourceDeferred
    }
}

export default useGenerateNotification
